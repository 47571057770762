import React from 'react';

const Contact = () => (
    <div className="page-container">
        <div className="page">
        <h1>Contact</h1>
        <p>Please drop us an email at <a href="mailto: intro@rapidauth.net">intro@rapidauth.net</a></p>
        </div>
    </div>
);

export default Contact;