import React , {useEffect} from 'react'
import HeroSection from '../HeroSection'


function Home () {

    /* A really stupid way to set the page title */
    useEffect(() => {
        document.title = "RapidAuth"
     }, []);

    return (
    <>
        <HeroSection />
    </>
    )
}

export default Home