import React from "react";
import '../App.css'
import { Button } from "./Button";
import './HeroSection.css'
import { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
  
function HeroSection () {

 // const axios = require("axios");

    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  
    const [rapidAuth, setRapidAuth] = useState([]);

    useEffect(() => {
      const getUserMetadata = async () => {
        
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: `https://api.rapidauth.net`,
              scope: "Access: All service",
            },
          });
          console.log ("access token" + accessToken)
          
          //const userDetailsByIdUrl = `https://api.rapidauth.net/`;
         const userDetailsByIdUrl = `http://localhost:8000/`;


          const serverResponse = await fetch(userDetailsByIdUrl, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
    
          const raResponse = await serverResponse.json();
    
          setRapidAuth ( raResponse.rapidauth );
          console.log (raResponse);

        } catch (e:unknown) {
          console.log ("uh oh" + e);
        }  finally  {
          console.log("finally" + rapidAuth );
          
        }
      };
    
      getUserMetadata();
    }, [getAccessTokenSilently, user?.sub]);



    return (
        <div className='hero-container'>
            <h1>&gt; App logins for the web </h1>
            <p>Improve security and usability {rapidAuth}</p>
            <div className="hero-btns">
                <Button className='btns' buttonStyle='btn--outline' buttonSize='btn--large'>
                Try it now</Button>
                
            </div>
        </div>
    )
}

export default HeroSection