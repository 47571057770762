import React from 'react';
import './App.css';
import {
    Route, Routes
} from 'react-router-dom'
import Home from './components/pages/Home'
import How from './components/pages/How';
import Developer from './components/pages/Developer';
import Contact from './components/pages/Contact'
import Profile from './components/pages/Profile'
import Navbar from './components/Navbar';
import Footer from './components/footer'


class App extends React.Component {
    render() {
        
        return (
            <div className="App">
                <Navbar />
                <div className="App-intro">
                    <Routes>
                        <Route path="/" Component={Home} />
                        <Route path="/how" Component={How} />
                        <Route path="/developer" Component={Developer} />
                        <Route path="/contact" Component={Contact} />
                        <Route path="/account" Component={Profile} />
                    </Routes>
                </div>
                <Footer/>
            </div>

        );
    }
}

export default App;
