import React from 'react';

const How = () => (
    <div className="page-container">
        <div className="page">
        <h1>How it works</h1>
        <p>RapidAuth is a new concept for the authentication model for websites.</p>
        <p>Rather than expect web users to authenticate with a web interface, resulting in the complex passing arouind of tokens between front and back end, RapidAuth allows the customer to make login decisions in a different channel, with authorisation being direcly provided to the back end.</p>
        <h2>Key benefits</h2>
        <ul>
            <li>No need for consumers to set, learn and remember separate passwords for each site</li>
            <li>Removes the habbit of entering credentials into websites, preventing phishing</li>
            <li>All enrolled sites are audited</li>
            <li>Provides alerting to use via separate channel</li>
            <li>Supports fallback channel for when app unavailable</li>
            <li>Privacy focussed, including state maintenace and push notifications, without the need to share ID</li>
        </ul>
        </div>
    </div>
);

export default How;