import React from 'react';

const Developer = () => (
    <div className="page-container">
        <div className="page">
        <h2>Developer</h2>
        </div>
    </div>
);

export default Developer;