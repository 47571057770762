import React, { useEffect, useState } from 'react';
import { Button } from '../../components/Button';

import { useAuth0 } from '@auth0/auth0-react';

const Profile = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [userMetadata, setUserMetadata] = useState(null);
  const [tokens, setTokens] = useState("");

  return (
    <div className="page-container">
      <div className="page">
        <h2>Profile</h2>
        <br/><br/>
        <p>You are logged in with the following profile:</p>
        <img
          src={user?.picture}
          alt={user?.name}
        />
        <p><strong>Name</strong> {user?.name}<br/>
        <strong>Email</strong> {user?.email}<br/>
        <strong>Sub (reference code)</strong> {user?.sub }<br/>
        <strong>Nickname</strong> {user?.nickname }</p>
        {/*userMetadata ? (
          <pre>{JSON.stringify(userMetadata, null, 2)}</pre>
        ) : (
          'No user metadata defined'
        )
        <Button
              buttonStyle="btn--outline"
              buttonSize="btn--small"
              onClick={() =>
                retrieveKeys()
              }
            >
              Do it
            </Button>*/}
      </div>
    </div>
  );
};

export default Profile;
