import React from 'react';
import './footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className="footer-container">
          <small className="website-rights"><Link to="/privacy">Privacy Policy</Link> | <Link to="/terms">Terms</Link> | © RapidAuth 2023</small>
    </div>
  );
}

export default Footer;
