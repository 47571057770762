import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { Button } from './Button';
import { useAuth0 } from '@auth0/auth0-react';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const { loginWithRedirect, logout, isAuthenticated, isLoading } =
    useAuth0();

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <nav className="navbar">
      {/* Logo, links to home */}
      <div className="navbar-container">
        <Link
          to="/"
          className="navbar-logo"
          onClick={closeMobileMenu}
        >
          <img
            src={logo}
            className="App-logo"
            alt="logo"
          />
        </Link>
        {/* Hamburger menu */}
        <div
          className="menu-icon"
          onClick={handleClick}
        >
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        <div className="desktop-nav">
          {/* Sign up link */}
          {!isAuthenticated && button && (
            <Button
              buttonStyle="btn--outline"
              buttonSize="btn--small"
              onClick={() => loginWithRedirect()}
            >
              Log in
            </Button>
          )}
          {isAuthenticated && button && (
            <Button
              buttonStyle="btn--outline"
              buttonSize="btn--small"
              onClick={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
            >
              Log out
            </Button>
          )}
          {/* Main nav */}
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className="nav-Item">
              <Link
                to="/how"
                onClick={closeMobileMenu}
                className="nav-links"
              >
                How it works
              </Link>
            </li>
            <li className="nav-Item">
              <Link
                to="/developer"
                onClick={closeMobileMenu}
                className="nav-links"
              >
                Developer
              </Link>
            </li>
            {isAuthenticated && (
              <li><Link
                to="/account"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                View Account
              </Link></li>
            )}
            <li className="nav-Item">
              <Link
                to="/contact"
                onClick={closeMobileMenu}
                className="nav-links"
              >
                Contact
              </Link>
            </li>
            <li className="nav-Item">
            {!isAuthenticated && (
            <Link
                to="/login"
                onClick={() =>  {
                  loginWithRedirect(); 
                  closeMobileMenu ();
                  }}
                className="nav-links-mobile"
              >
                Login
              </Link>)}
              {isAuthenticated && (
            <Link
                to="/logout"
                onClick={() =>  {
                  logout({ logoutParams: { returnTo: window.location.origin } }); 
                  closeMobileMenu ();
                  }}
                className="nav-links-mobile"
              >
                Logout
              </Link>)}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
export default Navbar;
